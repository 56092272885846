import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vue from 'vue';
import Vant, { Lazyload } from 'vant'
import 'vant/lib/index.css'
import { Swipe, SwipeItem, Dialog, Toast, NavBar } from 'vant'

import vueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { ImagePreview } from 'vant'

import moment from 'moment'
import AMapLoader from '@amap/amap-jsapi-loader'
import axios from 'axios'
import Cookies from 'js-cookie'

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL
axios.interceptors.request.use((config) => {
    if (config.method === 'POST') {
        const csrftoken = Cookies.get('csrftoken')
        config.headers['X-CSRFToken'] = csrftoken
    }
    return config
})

Toast.setDefaultOptions('loading', { forbidClick: true })
// 全局注册
Vue.use(ImagePreview)

Vue.use(vueAwesomeSwiper)

Vue.use(Vant)
Vue.use(Lazyload)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(NavBar)

Vue.config.productionTip = false
Vue.prototype.innerHeight = window.innerHeight
Vue.prototype.TAB_INDEX = 0
Vue.prototype.TAB_REVIEW = 1
Vue.prototype.TAB_REMATCH = 2
Vue.prototype.TAB_PROJECT = 3

Vue.prototype.IDENTITY_DEVELOPER = 0
Vue.prototype.IDENTITY_REVIEWER = 1
Vue.prototype.IDENTITY_MATCHER = 2

Vue.prototype.AREA_LIST = [
    '塘下镇',
    '陶山镇',
    '桐浦镇',
    '湖岭镇',
    '林川镇',
    '芳庄乡',
    '马屿镇',
    '曹村镇',
    '曹村镇',
    '平阳坑镇',
    '安阳街道',
    '玉海街道',
    '锦湖街道',
    '潘岱街道',
    '东山街道',
    '北麂乡',
    '上望街道',
    '莘塍街道',
    '汀田街道',
    '飞云街道',
    '云周街道',
    '仙降街道',
    '南滨街道'
]

Vue.prototype.jumpto = function (e, params) {
    if (e !== this.$route.path) {
        this.$router.push({
            path: e,
            query: params
        })
    }
}
Vue.prototype.dateFormat = function (stamp, format) {
    if (format === undefined) {
        format = 'YYYY年M月D日'
    }
    return moment.unix(stamp).format(format)
}
Vue.prototype.datetimeFormat = function (stamp, format) {
    if (format === undefined) {
        format = 'YYYY年M月D日 HH:mm'
    }
    return moment.unix(stamp).format(format)
}

Vue.prototype.implRangePick = function (dates, drop, param, title) {
    drop.toggle()
    const [rangeBegin, rangeEnd] = dates

    let dateFormat = 'YYYY-M-D'
    let beginFormat = this.dateFormat(rangeBegin / 1000, dateFormat)
    let endFormat = this.dateFormat(rangeEnd / 1000, dateFormat)
    this.params[param] = beginFormat + ',' + endFormat

    dateFormat = 'M/D'
    beginFormat = this.dateFormat(rangeBegin / 1000, dateFormat)
    endFormat = this.dateFormat(rangeEnd / 1000, dateFormat)
    this[title] = beginFormat + '-' + endFormat
}

Vue.prototype.avatarUrl = function (accessToken, avatarMediaId) {
    // 专有钉钉
    // return `https://openplatform.dg-work.cn/media/download?access_token=${accessToken}&media_id=${avatarMediaId}`
    // 浙政钉
    return `https://openplatform-pro.ding.zj.gov.cn/media/download?access_token=${accessToken}&media_id=${avatarMediaId}`
}
Vue.prototype.mediaUrl = process.env.VUE_APP_MEDIA_URL

Vue.mixin({
    data() {
        return {
            mediaUrl: process.env.VUE_APP_MEDIA_URL
        }
    }
})

// 注册高德地图组件到全局
AMapLoader.load({
    key: '273f7dc5cfaee74d6d1e234883ce709b', // 申请好的Web端开发者Key，首次调用 load 时必填
    version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: ['AMap.Geocoder'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
})
    .then((AMap) => {
        window.AMap = AMap
        console.log('地图组件导入成功')
    })
    .catch((e) => {
        console.log(e)
    })

// import eruda from 'eruda'
// eruda.init()
new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
